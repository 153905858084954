<div class="row">
  <div
    class="col-xs-12 col-sm-6 col-md-4 col-xl-3"
    *ngIf="
      isAreaTypeShow('DIVISION') &&
      (findWorkingAreaTypes(this.areaFilterTypeEnum.DIVISION) ||
        findWorkingAreaTypes(this.areaFilterTypeEnum.DISTRICT) ||
        findWorkingAreaTypes(this.areaFilterTypeEnum.UPAZILA) ||
        findWorkingAreaTypes(this.areaFilterTypeEnum.UNION) ||
        findWorkingAreaTypes(this.areaFilterTypeEnum.WARD)) &&
      !filterStaff
    "
  >
    <label
      class="col-form-label"
      [ngClass]="{ requiredMark: areaValidationGroupName != '' }"
      >Select Division</label
    >
    <div class="">
      <!-- valueExpr="division_id" -->
      <dx-tag-box
        class="col-sm-12"
        [items]="divisionList"
        displayExpr="division_name"
        [searchEnabled]="true"
        [searchExpr]="['division_name']"
        [showSelectionControls]="true"
        [showDropDownButton]="true"
        selectAllMode="page"
        [maxDisplayedTags]="4"
        [showMultiTagOnly]="false"
        [value]="selectedDIVISION"
        (onValueChanged)="divisionOptionChange($event)"
      >
        <div *dxTemplate="let data of 'dropDownButton'">
          <dx-load-indicator [visible]="divisionLoader"></dx-load-indicator>
          <i
            visible="!divisionLoader"
            class="dx-icon-spindown menu-icon dropdownIcon"
          ></i>
        </div>
        <!-- <dx-validator validationGroup="validationGroupDivision">
          <dxi-validation-rule type="required" message="Division Required">
          </dxi-validation-rule>
        </dx-validator> -->
        <dx-validator [validationGroup]="areaValidationGroupName">
          <dxi-validation-rule type="required" message="Required">
          </dxi-validation-rule>
        </dx-validator>
        <!-- <dx-validator [validationGroup]="validationGroupName" [validationRules]="validators"></dx-validator> -->
      </dx-tag-box>
    </div>
  </div>
  <div
    class="col-xs-12 col-sm-6 col-md-4"
    *ngIf="
      isAreaTypeShow('WARD') &&
      isWardShow &&
      findWorkingAreaTypes(this.areaFilterTypeEnum.HEAD_OFFICE) &&
      filterStaff
    "
  >
    <label class="col-form-label requiredMark">Select Head-Office Staff</label>
    <div class="">
      <!-- valueExpr="union_id" -->
      <dx-tag-box
        class="col-sm-12"
        [items]="headofficeStaffList"
        displayExpr="name_pin"
        [searchEnabled]="true"
        [searchExpr]="['name_pin']"
        [showSelectionControls]="true"
        [showDropDownButton]="true"
        selectAllMode="page"
        [maxDisplayedTags]="4"
        [showMultiTagOnly]="false"
        [value]="selectedHeadofficeStaff"
        [readOnly]="false"
        [wrapItemText]="true"
        [useItemTextAsTitle]="true"
        (onValueChanged)="staffChanged($event, areaFilterTypeEnum.HEAD_OFFICE)"
      >
        <div *dxTemplate="let data of 'dropDownButton'">
          <dx-load-indicator [visible]="unionLoader"></dx-load-indicator>
          <i
            visible="!unionLoader"
            class="dx-icon-spindown menu-icon dropdownIcon"
          ></i>
        </div>
        <dx-validator [validationGroup]="areaValidationGroupName">
          <dxi-validation-rule
            type="required"
            message="Must Select Head Office Staff!"
          >
          </dxi-validation-rule>
        </dx-validator>
      </dx-tag-box>
    </div>
  </div>
  <div
    class="col-xs-12 col-sm-6 col-md-4"
    *ngIf="
      isAreaTypeShow('WARD') &&
      isWardShow &&
      findWorkingAreaTypes(this.areaFilterTypeEnum.DIVISION) &&
      filterStaff
    "
  >
    <label class="col-form-label">Select Division Staff</label>
    <div class="">
      <!-- valueExpr="union_id" -->
      <dx-tag-box
        class="col-sm-12"
        [items]="divisionStaffList"
        displayExpr="name_pin"
        [searchEnabled]="true"
        [searchExpr]="['name_pin']"
        [showSelectionControls]="true"
        [showDropDownButton]="true"
        selectAllMode="page"
        [maxDisplayedTags]="4"
        [showMultiTagOnly]="false"
        [value]="selectedDIVISIONStaff"
        [readOnly]="false"
        [wrapItemText]="true"
        [useItemTextAsTitle]="true"
        (onValueChanged)="staffChanged($event, areaFilterTypeEnum.DIVISION)"
      >
        <div *dxTemplate="let data of 'dropDownButton'">
          <dx-load-indicator [visible]="unionLoader"></dx-load-indicator>
          <i
            visible="!unionLoader"
            class="dx-icon-spindown menu-icon dropdownIcon"
          ></i>
        </div>
        <dx-validator [validationGroup]="areaValidationGroupName">
          <!-- <dxi-validation-rule
            type="required"
            message="Union Required"
          >
          </dxi-validation-rule> -->
        </dx-validator>
      </dx-tag-box>
    </div>
  </div>

  <div
    class="col-xs-12 col-sm-6 col-md-4"
    *ngIf="
      isAreaTypeShow('DISTRICT') &&
      (findWorkingAreaTypes(this.areaFilterTypeEnum.DISTRICT) ||
        findWorkingAreaTypes(this.areaFilterTypeEnum.UPAZILA) ||
        findWorkingAreaTypes(this.areaFilterTypeEnum.UNION) ||
        findWorkingAreaTypes(this.areaFilterTypeEnum.WARD)) &&
      !filterStaff
    "
  >
    <label class="col-form-label">Select District</label>
    <div class="">
      <!-- /// valueExpr="district_id" /// -->
      <dx-tag-box
        class="col-sm-12"
        [items]="districtList"
        displayExpr="district_name"
        [searchEnabled]="true"
        [searchExpr]="['district_name']"
        [showSelectionControls]="true"
        [showDropDownButton]="true"
        selectAllMode="page"
        [maxDisplayedTags]="4"
        [showMultiTagOnly]="false"
        [value]="selectedDISTRICT"
        (onValueChanged)="districtOptionChange($event)"
      >
        <div *dxTemplate="let data of 'dropDownButton'">
          <dx-load-indicator [visible]="districtLoader"></dx-load-indicator>
          <i
            visible="!districtLoader"
            class="dx-icon-spindown menu-icon dropdownIcon"
          ></i>
        </div>
        <dx-validator [validationGroup]="areaValidationGroupName">
          <!-- //// <dxi-validation-rule
            type="required"
            message="District Required"
          >
          </dxi-validation-rule> //// -->
        </dx-validator>
      </dx-tag-box>
    </div>
  </div>
  <div
    class="col-xs-12 col-sm-6 col-md-4"
    *ngIf="
      isAreaTypeShow('WARD') &&
      isWardShow &&
      findWorkingAreaTypes(this.areaFilterTypeEnum.DISTRICT) &&
      filterStaff
    "
  >
    <label class="col-form-label">Select District Staff</label>
    <div class="">
      <!-- valueExpr="union_id" -->
      <dx-tag-box
        class="col-sm-12"
        [items]="districtStaffList"
        displayExpr="name_pin"
        [searchEnabled]="true"
        [searchExpr]="['name_pin']"
        [showSelectionControls]="true"
        [showDropDownButton]="true"
        selectAllMode="page"
        [maxDisplayedTags]="4"
        [showMultiTagOnly]="false"
        [value]="selectedDISTRICTStaff"
        [readOnly]="false"
        [wrapItemText]="true"
        [useItemTextAsTitle]="true"
        (onValueChanged)="staffChanged($event, areaFilterTypeEnum.DISTRICT)"
      >
        <div *dxTemplate="let data of 'dropDownButton'">
          <dx-load-indicator [visible]="unionLoader"></dx-load-indicator>
          <i
            visible="!unionLoader"
            class="dx-icon-spindown menu-icon dropdownIcon"
          ></i>
        </div>
        <dx-validator [validationGroup]="areaValidationGroupName">
          <!-- <dxi-validation-rule
            type="required"
            message="Union Required"
          >
          </dxi-validation-rule> -->
        </dx-validator>
      </dx-tag-box>
    </div>
  </div>
  <div
    class="col-xs-12 col-sm-6 col-md-4 col-xl-3"
    *ngIf="
      isAreaTypeShow('SUB_DISTRICT') &&
      (findWorkingAreaTypes(this.areaFilterTypeEnum.UPAZILA) ||
        findWorkingAreaTypes(this.areaFilterTypeEnum.UNION) ||
        findWorkingAreaTypes(this.areaFilterTypeEnum.WARD)) &&
      !filterStaff
    "
  >
    <label class="col-form-label">Select Upazila</label>
    <div class="">
      <!-- // valueExpr="sub_district_id" // -->
      <dx-tag-box
        class="col-sm-12"
        [items]="subDistrictList"
        displayExpr="sub_district_name"
        [searchEnabled]="true"
        [searchExpr]="['sub_district_name']"
        [showSelectionControls]="true"
        [showDropDownButton]="true"
        selectAllMode="page"
        [maxDisplayedTags]="4"
        [showMultiTagOnly]="false"
        [value]="selectedSUB_DISTRICT"
        (onValueChanged)="subDistrictOptionChange($event)"
      >
        <div *dxTemplate="let data of 'dropDownButton'">
          <dx-load-indicator [visible]="subdistrictLoader"></dx-load-indicator>
          <i
            visible="!subdistrictLoader"
            class="dx-icon-spindown menu-icon dropdownIcon"
          ></i>
        </div>
        <dx-validator [validationGroup]="areaValidationGroupName">
          <!-- /// <dxi-validation-rule
            type="required"
            message="Upazila Required"
          >
          </dxi-validation-rule> /// -->
        </dx-validator>
      </dx-tag-box>
    </div>
  </div>
  <div
    class="col-xs-12 col-sm-6 col-md-4"
    *ngIf="
      isAreaTypeShow('WARD') &&
      isWardShow &&
      findWorkingAreaTypes(this.areaFilterTypeEnum.UPAZILA) &&
      filterStaff
    "
  >
    <label class="col-form-label">Select Upazila Staff</label>
    <div class="">
      <!-- valueExpr="union_id" -->
      <dx-tag-box
        class="col-sm-12"
        [items]="upazilaStaffList"
        displayExpr="name_pin"
        [searchEnabled]="true"
        [searchExpr]="['name_pin']"
        [showSelectionControls]="true"
        [showDropDownButton]="true"
        selectAllMode="page"
        [maxDisplayedTags]="4"
        [showMultiTagOnly]="false"
        [value]="selectedSUB_DISTRICTStaff"
        [readOnly]="false"
        [wrapItemText]="true"
        [useItemTextAsTitle]="true"
        (onValueChanged)="staffChanged($event, areaFilterTypeEnum.UPAZILA)"
      >
        <div *dxTemplate="let data of 'dropDownButton'">
          <dx-load-indicator [visible]="unionLoader"></dx-load-indicator>
          <i
            visible="!unionLoader"
            class="dx-icon-spindown menu-icon dropdownIcon"
          ></i>
        </div>
        <dx-validator [validationGroup]="areaValidationGroupName">
          <!-- <dxi-validation-rule
            type="required"
            message="Union Required"
          >
          </dxi-validation-rule> -->
        </dx-validator>
      </dx-tag-box>
    </div>
  </div>
  <div
    class="col-xs-12 col-sm-6 col-md-4 col-xl-3"
    *ngIf="
      isAreaTypeShow('UNION') &&
      (findWorkingAreaTypes(this.areaFilterTypeEnum.UNION) ||
        findWorkingAreaTypes(this.areaFilterTypeEnum.WARD)) &&
      !filterStaff
    "
  >
    <label class="col-form-label">Select Union</label>
    <div class="">
      <!-- // valueExpr="union_id" // -->
      <dx-tag-box
        class="col-sm-12"
        [items]="unionList"
        displayExpr="union_name"
        [searchEnabled]="true"
        [searchExpr]="['union_name']"
        [showSelectionControls]="true"
        [showDropDownButton]="true"
        selectAllMode="page"
        [maxDisplayedTags]="4"
        [showMultiTagOnly]="false"
        [value]="selectedUNION"
        (onValueChanged)="unionOptionChange($event)"
      >
        <div *dxTemplate="let data of 'dropDownButton'">
          <dx-load-indicator [visible]="unionLoader"></dx-load-indicator>
          <i
            visible="!unionLoader"
            class="dx-icon-spindown menu-icon dropdownIcon"
          ></i>
        </div>
        <dx-validator [validationGroup]="areaValidationGroupName">
          <!-- /// <dxi-validation-rule
            type="required"
            message="Union Required"
          >
          </dxi-validation-rule> /// -->
        </dx-validator>
      </dx-tag-box>
    </div>
  </div>
  <div
    class="col-xs-12 col-sm-6 col-md-4"
    *ngIf="
      isAreaTypeShow('WARD') &&
      isWardShow &&
      findWorkingAreaTypes(this.areaFilterTypeEnum.UNION) &&
      filterStaff
    "
  >
    <label class="col-form-label">Select Union Staff</label>
    <div class="">
      <!-- valueExpr="union_id" -->
      <dx-tag-box
        class="col-sm-12"
        [items]="unionStaffList"
        displayExpr="name_pin"
        [searchEnabled]="true"
        [searchExpr]="['name_pin']"
        [showSelectionControls]="true"
        [showDropDownButton]="true"
        selectAllMode="page"
        [maxDisplayedTags]="4"
        [showMultiTagOnly]="false"
        [value]="selectedUNIONStaff"
        [readOnly]="false"
        [wrapItemText]="true"
        [useItemTextAsTitle]="true"
        (onValueChanged)="staffChanged($event, areaFilterTypeEnum.UNION)"
      >
        <div *dxTemplate="let data of 'dropDownButton'">
          <dx-load-indicator [visible]="unionLoader"></dx-load-indicator>
          <i
            visible="!unionLoader"
            class="dx-icon-spindown menu-icon dropdownIcon"
          ></i>
        </div>
        <dx-validator [validationGroup]="areaValidationGroupName">
          <!-- <dxi-validation-rule
            type="required"
            message="Union Required"
          >
          </dxi-validation-rule> -->
        </dx-validator>
      </dx-tag-box>
    </div>
  </div>
  <div
    class="col-xs-12 col-sm-6 col-md-4 col-xl-3"
    *ngIf="
      isAreaTypeShow('WARD') &&
      isWardShow &&
      findWorkingAreaTypes(this.areaFilterTypeEnum.WARD) &&
      !filterStaff
    "
  >
    <label class="col-form-label">Select Ward</label>
    <div class="">
      <!-- /// valueExpr="ward_id" /// -->
      <dx-tag-box
        class="col-sm-12"
        [items]="wardList"
        displayExpr="ward_name"
        [searchEnabled]="true"
        [searchExpr]="['ward_name']"
        [showSelectionControls]="true"
        [showDropDownButton]="true"
        selectAllMode="page"
        [maxDisplayedTags]="4"
        [showMultiTagOnly]="false"
        [value]="selectedWARD"
        (onValueChanged)="wardOptionChange($event)"
      >
        <div *dxTemplate="let data of 'dropDownButton'">
          <dx-load-indicator [visible]="wardLoader"></dx-load-indicator>
          <i
            visible="!wardLoader"
            class="dx-icon-spindown menu-icon dropdownIcon"
          ></i>
        </div>
        <dx-validator [validationGroup]="areaValidationGroupName">
          <!-- //// <dxi-validation-rule
            type="required"
            message="Ward Required"
          >
          </dxi-validation-rule> //// -->
        </dx-validator>
      </dx-tag-box>
    </div>
  </div>
  <div
    class="col-xs-12 col-sm-6 col-md-4"
    *ngIf="
      isAreaTypeShow('WARD') &&
      isWardShow &&
      findWorkingAreaTypes(this.areaFilterTypeEnum.WARD) &&
      filterStaff
    "
  >
    <label class="col-form-label">Select Ward Staff</label>
    <div class="">
      <!-- valueExpr="union_id" -->
      <dx-tag-box
        class="col-sm-12"
        [items]="wardStaffList"
        displayExpr="name_pin"
        [searchEnabled]="true"
        [searchExpr]="['name_pin']"
        [showSelectionControls]="true"
        [showDropDownButton]="true"
        selectAllMode="page"
        [maxDisplayedTags]="4"
        [showMultiTagOnly]="false"
        [value]="selectedWARDStaff"
        [readOnly]="false"
        [wrapItemText]="true"
        [useItemTextAsTitle]="true"
        (onValueChanged)="staffChanged($event, areaFilterTypeEnum.WARD)"
      >
        <div *dxTemplate="let data of 'dropDownButton'">
          <dx-load-indicator [visible]="unionLoader"></dx-load-indicator>
          <i
            visible="!unionLoader"
            class="dx-icon-spindown menu-icon dropdownIcon"
          ></i>
        </div>
        <dx-validator [validationGroup]="areaValidationGroupName">
          <!-- <dxi-validation-rule
            type="required"
            message="Union Required"
          >
          </dxi-validation-rule> -->
        </dx-validator>
      </dx-tag-box>
    </div>
  </div>
</div>
